import { useRef, useEffect, Fragment } from 'react';
import * as d3 from 'd3';
import './Bars.css';
import SVGImage from '../assets/disconnected.svg';

function Bars({ hostname, connectionStatus, value })
{
  const initialData = [0];

  const data = useRef(initialData);

  const svgRef = useRef(null);
  const maxY = 100;                                      // maximum input number

  // console.log('Bars | value:', value);

  function effect()
  {
    const zoomFactor = 1.5;

    let dim =
    {
      width  : 200,
      height : 350,
      margin:
      {
        top    : 50,
        right  : 50,
        bottom : 50,
        left   : 50
      }
    }

    let stageWidth  = dim.width  - dim.margin.left - dim.margin.right,
        stageHeight = dim.height - dim.margin.top  - dim.margin.bottom;

    // create a new SVG element
    const svg = d3
      .select(svgRef.current)
      .attr('overflow', 'visible')
      .attr('width' , dim.width)
      .attr('height', dim.height);

    svg.selectAll('*').remove();

    svg.append('rect')                                // drawing the bounds of the SVG element
      .attr('id', 'outer')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', dim.width)
      .attr('height', dim.height);

    svg.append('rect')                                // drawing the bounds of the stage container
      .attr('id', 'inner')
      .attr('x', dim.margin.left)
      .attr('y', dim.margin.top)
      .attr('width',  dim.width  - dim.margin.left - dim.margin.right)
      .attr('height', dim.height - dim.margin.top  - dim.margin.bottom);

    const stage = svg.append('g')
      .attr('transform', `translate(${dim.margin.left}, ${dim.margin.top})`);

    function getIndex(_, i)
    {
      return i;
    }
    
    const xScale = d3
      .scaleBand()
      .domain(data.current.map(getIndex))
      .range([0, stageWidth])
      .paddingOuter(0.005);
    
    const yScale = d3
      .scaleLinear()
      .domain([0, maxY])
      .range([stageHeight, 0]);

    const xAxisGenerator = d3.axisBottom(xScale);              // creating the x-axis generator
    const yAxisGenerator = d3.axisLeft(yScale);                // creating the y-axis generator
    
    const xAxisGroup = stage                                   // SVG group for the x-axis
      .append('g')                                             // appending the x-axis group
      .classed('x axis', true)                                 // assigning the 'x' and 'axis' classes
      .style('transform', `translateY(${stageHeight}px)`)      // positioning the x-axis at the bottom
      .call(xAxisGenerator);                                   // drawing the x-axis
    
    stage                                                      // SVG group for the y-axis
      .append('g')                                             // appending the y-axis group
      .classed('y axis', true)                                 // assigning the 'y' and 'axis' classes
      .call(yAxisGenerator);                                   // drawing the y-axis

    // x-axis label                                              <text> element
    xAxisGroup
      .append('text')                                          // appending to the group
      .attr('x', stageWidth / 2)                               // centering horizontally
      .attr('y', dim.margin.bottom - 15)                       // positioning vertically
      .style('font-size', `${zoomFactor}em`)
      .text(hostname);
   
    stage
      .selectAll('.bar')
      .data(data.current)
      .join('rect')
      .attr('class', 'bar')
      .attr('x', (_, i) => xScale(i))
      .attr('y', d => yScale(d))
      .attr('width', xScale.bandwidth())
      .attr('height', d => stageHeight - yScale(d))
      .style('fill', 'IndianRed');

    if (!connectionStatus)
    {
      svg                                                         // image: disconnected
        .append('image')
        .attr('xlink:href', SVGImage)
        .attr('id', 'disconnected')
        .attr('x', 75)
        .attr('y', 150)
        .attr('width', 50)
        .attr('height', 50)
        .attr('stroke', 'white');
    }
  

    let newData = [...data.current];
    newData.shift();
    newData.push(value);
    
    stage
      .selectAll('.bar')
      .data(newData)
      .transition()
      .duration(700)
      .attr('x', (_, i) => xScale(i))
      .attr('y', d => yScale(d))
      .attr('height', d => stageHeight - yScale(d));

    data.current = newData;
  }

  useEffect(effect, [hostname, connectionStatus, value]);

  const result =
    <Fragment>
      <svg ref={ svgRef }></svg>
    </Fragment>;
    
  return result;
}

export default Bars;